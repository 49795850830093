<template>
  <section id="dashboard-analytics">
    <b-row class="mx-0 match-height">
      <b-col lg="12" sm="12">
        <b-card
          text-variant="center"
          class="card card-congratulations"
          style="height: 300px"
        >
          <b-card-body class="h-100">
            <b-img
              :src="require('@/assets/images/elements/decore-left.png')"
              class="congratulations-img-left"
            />
            <b-img
              :src="require('@/assets/images/elements/decore-right.png')"
              class="congratulations-img-right"
            />
            <b-avatar variant="warning" size="70" class="shadow mb-2">
              <feather-icon size="28" icon="AwardIcon" />
            </b-avatar>
            <h2 class="mb-1 text-white" style="margin-top: 1px">
              {{ $t("Pages.Assistant.Title.know_ch") }}
            </h2>
            <h2 class="mb-1 text-white" style="margin-top: 1px">
              {{ $t("Pages.Assistant.Title.grow_ch") }}
            </h2>
            <b-link @click="GoToNewRequest">
              <b-button class="my-1" variant="primary" size="lg">{{
                $t("Pages.Assistant.Title.new_req")
              }}</b-button>
            </b-link>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col lg="12" sm="12" class="">
        <b-card no-body v-if="showChart">
          <b-card-header
            class="d-flex justify-content-between align-items-center"
          >
            <h4>{{ $t("Pages.Assistant.Title.compar_chart") }}</h4>
            <div class="mx-1" v-if="listChannelsInReviews.length > 0">
              <b-dropdown :text="text_dropdown" variant="outline-primary">
                <b-dropdown-item
                  v-for="channel in listChannelsInReviews"
                  :key="channel.id"
                  @click="getNewDataInCharts(channel)"
                >
                  {{ channel.channel_name }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </b-card-header>

          <!-- chart -->
          <b-card-body>
            <chartjs-component-line-chart
              :height="430"
              :data="chartjsData.reviews_charts.data"
              :options="chartjsData.reviews_charts.options"
              :plugins="plugins"
            />
          </b-card-body>
        </b-card>
      </b-col>
      <!-- ///////table section///// -->
      <b-col lg="12" sm="12">
        <b-card v-if="tableData" no-body class="card-company-table text-center">
          <b-card-header>
            <b-card-title>{{
              $t("Pages.Assistant.Title.his_review")
            }}</b-card-title>
          </b-card-header>
          <b-table
            :items="tableData"
            responsive
            :fields="fields"
            class="mb-0"
            show-empty
            :empty-text="
              $t(
                'Pages.Assistant.Table.no_record_found'
              )
            "
          >
            <template #cell(name)="data">
              <b-link
                :to="{
                  name: 'Assistant',
                  params: {
                    channel_id: data.item.channel_id,
                    uuid: data.item.uuid,
                  },
                }"
              >
                <div class="d-flex align-items-center">
                  <b-avatar rounded size="32" variant="light-company">
                    <b-img
                      class="img-fluid"
                      :src="data.item.channel_avatar"
                      alt="avatar img"
                    />
                  </b-avatar>
                  <div class="ml-1">
                    <div class="font-weight-bolder">
                      {{ data.item.channel_name }}
                    </div>
                  </div>
                </div>
              </b-link>
            </template>

            <template #cell(type)="data">
              <div class="d-flex flex-column">
                <span class="font-weight-normal mr-1">{{
                  data.item.mentor_id
                    ? $t("Pages.Assistant.Table.by_mentor")
                    : $t("Pages.Assistant.Table.by_myself")
                }}</span>
              </div>
            </template>

            <template #cell(subscriber)="data">
              <div class="d-flex flex-column">
                <span class="font-weight-normal mr-1">{{
                  data.item.subscribers
                }}</span>
              </div>
            </template>

            <template #cell(video)="data">
              <div class="d-flex flex-column">
                <span class="font-weight-normal mr-1">{{
                  data.item.videos
                }}</span>
              </div>
            </template>

            <template #cell(c_score)="data">
              <div class="d-flex flex-column">
                <span
                  :class="
                    data.item.c_score > 70
                      ? 'text-success font-weight-bold mr-1'
                      : 'text-danger font-weight-normal mr-1'
                  "
                  >{{ data.item.c_score }}</span
                >
              </div>
            </template>

            <template #cell(v_score)="data">
              <div class="d-flex flex-column">
                <span
                  :class="
                    data.item.v_score > 70
                      ? 'text-success font-weight-bold mr-1'
                      : 'text-danger font-weight-normal mr-1'
                  "
                  >{{ data.item.v_score }}</span
                >
              </div>
            </template>

            <template #cell(count_video_analysis)="data">
              <div class="d-flex flex-column">
                <span class="font-weight-normal mr-1">{{
                  data.item.count_video
                }}</span>
              </div>
            </template>

            <!-- <template #cell(count_competitors_channels)="data">
                            <div class="d-flex flex-column">
                                <span class="font-weight-normal mr-1">{{ data.item.competitors_channels }}</span>
                            </div>
                        </template> -->
            <template #cell(date)="data">
              <div class="d-flex align-items-center text-center">
                <span class="font-weight-normal mr-1 text-center">{{
                  MyDate.getFormatDate(data.item.created_at)
                }}</span>
              </div>
            </template>
            <template #cell(actions)="data">
              <div class="d-flex align-items-center">
                <b-link
                  v-if="data.item.v_score && data.item.c_score"
                  :to="{
                    name: 'Assistant',
                    params: {
                      channel_id: data.item.channel_id,
                      uuid: data.item.uuid,
                    },
                  }"
                >
                  <b-button variant="primary">{{
                    $t("Pages.Assistant.Button.show_review")
                  }}</b-button>
                </b-link>
                <b-link v-if="!data.item.v_score && !data.item.c_score">
                  <b-button variant="success">{{
                    $t("Pages.Assistant.Button.Analyzing")
                  }}</b-button>
                </b-link>
              </div>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row v-if="pages > 1">
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination-nav
                  :link-gen="linkGen"
                  :number-of-pages="pages"
                  use-router
                  class="mb-0"
                />
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCardText,
  BAvatar,
  BTable,
  BImg,
  BBadge,
  BLink,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardHeader,
  BCardBody,
  BOverlay,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BButton,
  BCard,
  BAlert,
  BFormDatepicker,
  BInputGroup,
  BCardTitle,
  BModal,
  BInputGroupAppend,
  BPaginationNav,
  BCardSubTitle,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import chartjsData from "./components/chartjsData";
import ChartjsComponentLineChart from "../charts/charts-components/ChartjsComponentLineChart.vue";
import MyDate from "@core/components/date/MyDate.vue";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BCard,
    BAlert,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BCardText,
    BAvatar,
    BTable,
    BImg,
    BBadge,
    BLink,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardHeader,
    BModal,
    BCardBody,
    BOverlay,
    BCardTitle,
    BPaginationNav,
    BCardSubTitle,
    VueApexCharts,
    ChartjsComponentLineChart,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
  },
  data() {
    return {
      MyDate,
      PermissionRequestAnalysis: false,
      plugins: [
        {
          beforeInit(chart) {
            chart.legend.afterFit = function () {
              this.height += 20;
            };
          },
        },
      ],
      chartjsData:chartjsData,
      tableData: [],
      fields: [
        { key: "name", label: this.$t("Pages.Assistant.Table.ch_name") },
        { key: "type", label: this.$t("Pages.Assistant.Table.analyze_type") },
        {
          key: "subscriber",
          label: this.$t("Pages.Assistant.Table.Subscriber"),
        },
        { key: "video", label: this.$t("Pages.Assistant.Table.Videos") },
        { key: "c_score", label: this.$t("Pages.Assistant.Table.c_score") },
        { key: "v_score", label: this.$t("Pages.Assistant.Table.v_score") },
        {
          key: "count_video_analysis",
          label: this.$t("Pages.Assistant.Table.count_vid"),
        },
        // { key: "count_competitors_channels", label: this.$t("Count Competitors Channels Analysis") },
        { key: "date", label: this.$t("Pages.Assistant.Table.Date") },
        { key: "actions", label: this.$t("Pages.Assistant.Table.Operation") },
      ],
      pages: 0,
      listChannelsInReviews: [],
      text_dropdown: "",
      showChart:false
    };
  },
  created() {
    this.$http.get("/api/check_login").then(({ data }) => {
      let permissions = [];
      if (data.permissions.length != 0) {
        data.permissions.map((item) => {
          permissions.push(item.name);
        });
      }
      if (permissions.includes("Request Analysis")) {
        this.PermissionRequestAnalysis = true;
      }
    });
    this.getListChannelsInReviews();
    this.text_dropdown = this.$t("Pages.Assistant.Title.select_ch");
    this.getStaticsReviews();
    this.getListReviews();
  },
  watch: {
    $route(to, from) {
      if (to.query != from.query) {
        this.getListReviews();
      }
    },
  },
  methods: {
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },
    getStaticsReviews() {
      this.$http
        .get(`/api/tools/assistant/reviews/statics`)
        .then(({ data }) => {
          this.remaining = data.remaining;
          this.available = data.available;
          this.average = [data.average];
        });
    },
    getListReviews() {
      this.$http
        .get("/api/tools/assistant/table/reviews", {
          params: { page: this.$route.query.page },
        })
        .then((response) => {
          
          this.tableData = response.data.data;
          this.pages = response.data.last_page;
        });
    },
    getListChannelsInReviews() {
      this.$http
        .get("/api/tools/assistant/charts/reviews/channels")
        .then((response) => {
          this.listChannelsInReviews = [...response.data];
          if (this.listChannelsInReviews.length > 0) {
            this.getNewDataInCharts(response.data[0]);
          }
        });
    },
    getNewDataInCharts(channel) {
      this.text_dropdown = channel?.channel_name;
      this.showChart = false;
      this.$http
        .get(`/api/tools/assistant/charts/reviews?id=${channel?.channel_id}`)
        .then(({ data }) => {
          this.chartjsData.reviews_charts.options.scales.yAxes[0].ticks.max = 100;
          this.chartjsData.reviews_charts.data.labels = data.labels;
          this.chartjsData.reviews_charts.data.datasets[0].label = this.$t(
            "Pages.Assistant.Table.c_score"
          );
          this.chartjsData.reviews_charts.data.datasets[0].data = data.c_scores;
          this.chartjsData.reviews_charts.data.datasets[1].label = this.$t(
            "Pages.Assistant.Table.v_score"
          );
          this.chartjsData.reviews_charts.data.datasets[1].data = data.v_scores;
          this.showChart = true;
        });
    },
    GoToNewRequest() {
      if (this.PermissionRequestAnalysis) {
        this.$router.push({ name: "assistant-reviews-new" });
      } else {
        this.openPermissionDenid();
      }
    },
    openPermissionDenid() {
      this.$swal({
        title: this.$t("Base.Alert.Error"),
        text: this.$t(
          "Navbar.Title.need_golden"
        ),
        icon: "warning",
        confirmButtonText: this.$t("Navbar.Button.upgrade_account"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$router.push("/pricing").catch(() => {});
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.card-congratulations {
  background: linear-gradient(to right, #a2c00b, #90b234, #81bc74);
}
</style>
